import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useAboutIntroQuery } from "../../../hooks/useAboutIntroQuery"
import { Wrapper, StyledImg } from "./AboutIntro.styles"

const AboutIntro = () => {
  
    
  const {
    wpPage: { about: data },
  } = useAboutIntroQuery()

  const introText = data.intro
  const imageData = getImage(data.introImage.localFile)
  const imageAlt = data.introImage.altText


  
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
            <div className="column">
                <StyledImg image={imageData} alt={imageAlt} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default AboutIntro
