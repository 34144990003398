import { useAboutHeroQuery } from "../../../hooks/useAboutHeroQuery"

const AboutHeroMask = () => {
  
  const {
    wpPage: { about: data },
  } = useAboutHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default AboutHeroMask
