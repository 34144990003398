import { useStaticQuery, graphql } from "gatsby"

export const useTeamQuery = () => {
  const data = useStaticQuery(graphql`
  query TeamQuery {
    wpPage(databaseId: {eq: 177}) {
      id
      about {
        teamTitle
        team {
          name
          title
          photo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
  `)

  return data
}



