import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useAboutHeroQuery } from "../../../hooks/useAboutHeroQuery"
import { Wrapper, StyledImg } from "./AboutHero.styles"

const AboutHero = () => {
    
  const {
    wpPage: { about: data },
  } = useAboutHeroQuery()

  const imageData = getImage(data.hero.localFile)
  const heroAltText = data.hero.altText

  
  return (
    <Wrapper>
      <div className="container">
        <StyledImg id="hero-image" image={imageData} alt={heroAltText} />
      </div>
    </Wrapper>
  )
}

export default AboutHero
