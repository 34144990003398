import { useStaticQuery, graphql } from "gatsby"

export const useChatQuery = () => {
  const data = useStaticQuery(graphql`
    query ChatQuery {
      wpPage(databaseId: {eq: 185}) {
        id
        ACF_Homepage {
          chatImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            altText
          }
          chatButton {
            url
            title
            target
          }
          chatText
        }
      }
    }
  `)

  return data
}
