import React from "react"
import { useTeamQuery } from "../../../hooks/useTeamQuery"
import { Wrapper, StyledImg } from "./Team.styles"

const Team = () => {
  
  const {
    wpPage: { about: data },
  } = useTeamQuery()

  //const imageData = getImage(data.team.localFile)
  
  return (
    <Wrapper>
      <div className="container">
        <div className="columns team-title">
            <div className="column">
                <h2>{data.teamTitle}</h2>
            </div>
        </div>
        <div className="columns team-members">
        {data.team.map( (description, i) => (
            <div className="column" key={i}>
            <StyledImg image={description.photo.localFile.childImageSharp.gatsbyImageData} alt={description.name} />
            <h3>{description.name}</h3>
            <p>{description.title}</p>
            </div>
        ))}</div>
        </div>
    </Wrapper>
  )
}

export default Team
