import React from 'react';
import Layout from "../components/Layout/Layout"
import Seo from '../components/Seo/Seo';
import AboutHero from '../components/About/AboutHero/AboutHero';
import LetsChat from '../components/Home/LetsChat/LetsChat';
import Team from '../components/About/Team/Team';
import AboutIntro from '../components/About/AboutIntro/AboutIntro';
import Motto from '../components/About/Motto/Motto';

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <AboutHero />
    <AboutIntro />
    <Motto />
    <Team />
    <LetsChat />
  </Layout>
)


export default AboutPage;
