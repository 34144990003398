import { useStaticQuery, graphql } from "gatsby"

export const useMottoQuery = () => {
  const data = useStaticQuery(graphql`
    query MottoQuery {
        wpPage(databaseId: {eq: 177}) {
            id
            about {
              motto
              mottoImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
