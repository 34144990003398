import { useStaticQuery, graphql } from "gatsby"

export const useAboutHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query AboutHeroQuery {
      wpPage(databaseId: {eq: 177}) {
        id
        about {
          hero {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          heroMask {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data
}
