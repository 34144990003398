import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 285px;
  object-position: top;
  margin-bottom: 0.75rem;
  img {
    object-position: top;
  }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 2rem 0 8rem;
  @media screen and (max-width: 991px) {
    padding: 0 0 8rem;
  }
  @media screen and (max-width: 991px) {
    padding: 0 0 2rem;
  }

  .container{
    .columns.team-title {
        text-align: center; 
        h2 {
            margin-bottom: 2.5rem;
        }
    }
    .columns.team-members{
        flex-wrap: wrap;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        width: calc(100% + 1rem);
        @media screen and (max-width: 991px) {
            width: calc(100% + 1rem);
        }
        .column{
            flex: none;
            padding: 0.5rem;
            width: calc(25% - 1rem);
            @media screen and (max-width: 991px) {
                width: calc(50% - 1rem);
            }
            h3 {
                margin: 0;
            }
            p{
                font-size: 18px;
            }
        }
    }
  }
`